@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.hero-component {
  position: relative;
  //-124px needed to account for mobile browser address bar etc.
  //(on mobile 100vh includes address bar and content gets hidden behind it)
  height: calc(100vh - 124px);
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: @screen-lg) {
    height: calc(100vh - @ThuleHelloBarHeight);
  }

  .tint-layer,
  .gradient-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .img-background-layer {
    background-color: @PitchBlack;
    height: 100%;
  }

  img,
  video {
    width: unset;
    min-width: 100%;
    max-width: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    display: flex;

    @media screen and (min-width: @screen-lg) {
      //+175px needed to get correct full-width since the browsers address bar, tab bar etc.
      //takes up space and throws off the fullscreen 16:9 ratio
      height: calc(100vh + 175px);
    }
  }

  .tint-layer {
    background-color: @PitchBlack;
    opacity: 0.2;
  }

  .gradient-layer {
    &::before,
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 40%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    }

    &::before {
      top: 0;
      transform: rotate(-180deg);
    }

    &::after {
      bottom: 0;
    }
  }

  .text-box {
    position: absolute;
    bottom: 0;

    * {
      color: @White;
    }

    .title,
    .tag-line {
      text-shadow: 0px 4px 70px rgba(0, 0, 0, 0.6);
    }
  }

  a {
    @media screen and (min-width: @screen-lg) {
      max-width: 300px;
    }
  }
}
